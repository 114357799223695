import React from "react";
import Layout from "../../components/Layout";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";

export default (props) => (
  <Layout location={props.location}>
    <SEO title="LifePlan Application - Form Submitted" />
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <section className="uk-container thankyousection">
      <div className="container content">
        <div className="thankyoudiv">
          <div className="thankyou">
            <h1 class="uk-text-large">Thank you!</h1>
            <p className="uk-margin-small-top">
              We'll get back to you shortly.
            </p>
            <AniLink fade to="/">
              <button className="uk-margin-large-top uk-button uk-button-default">
                Back to Home
              </button>
            </AniLink>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
